<template>
  <window-content class="e-input-modern label-size-2 size1">
    <div class="wrapper-sm">
      <e-row mr>
        <e-col>
          <erp-s-field label="Processo">
            <erp-input v-model="filters.processo" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 350px">
          <erp-s-field
              label="Cliente:"
          >
            <cliente-processo-input v-model="filters.cliente" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 350px">
          <erp-s-field
              label="Envolvido:"
          >
            <cliente-processo-input v-model="filters.envolvido" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 200px">
          <tribunal-select v-model="filters.tribunal" />
        </e-col>

        <e-col style="max-width: 300px">
          <comarca-select v-model="filters.comarca" :tribunal="filters.tribunal" :limit="10000" />
        </e-col>

        <e-col>
          <vara-select v-model="filters.vara" :comarca="filters.comarca" />
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="min-width: 126px; max-width: 126px">
          <tipo-processo-select select-null v-model="filters.tipo" />
        </e-col>
        <e-col>
          <date-input label="Data (De)" v-model="filters.data1" />
        </e-col>

        <e-col class="col" >
          <date-input label="Data (Até)" v-model="filters.data2" />
        </e-col>

        <e-col class="col">
          <erp-s-field
              view="tl"
              label="Tipo Data"
          >
            <erp-select
                placeholder="Qualquer"
                :options="[
                        {label: 'Última Publicação', value: 'publicacao'},
                        {label: 'Registro', value: 'registro'},
                        {label: 'Leilão Designado', value: 'leilao'},
                    ]"
                v-model="filters.tipoData" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <status-processo-select v-model="filters.status" />
        </e-col>
        <e-col>
          <classificacao-processo-select v-model="filters.classificacao" :status="filters.status" />
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 200px">
          <erp-s-field
              view="tl"
              label="Tipo de Relatório"
          >
            <erp-select
                :options="[
                          {label: 'Processos', value: 'processos'},
                          {label: 'Checklist de Bens', value: 'checklist'},
                      ]"
                v-model="filters.tipoRelatorio" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel,
  ErpSelect
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import {datePtToEn} from "@/utils/date"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import TribunalSelect from "@/components/processos/components/processos/include/TribunalSelect"
import ComarcaSelect from "@/components/processos/components/processos/include/ComarcaSelect"
import VaraSelect from "@/components/processos/components/processos/include/VaraSelect"
import ClienteProcessoInput from "@/components/processos/components/cliente/input/ClienteProcessoInput"
import processoMixins from "@/components/processos/components/processos/mixins/ProcessoMixins"
import searchTags from "@/components/pessoa/helpers/searchTags"
import StatusProcessoSelect from "@/components/processos/components/processos/include/StatusProcessoSelect"
import TipoProcessoSelect from "@/components/processos/components/processos/include/TipoProcessoSelect"
import DateInput from "@/reuse/input/Date"
import ClassificacaoProcessoSelect
  from "@/components/processos/components/processos/include/ClassificacaoProcessoSelect"

let filters = {
  processo: null,
  cliente: null,
  advogado: null,
  envolvido: null,
  tribunal: null,
  comarca: null,
  vara: null,
  status: null,
  classificacao: null,
  tipo: '',
  data1: null,
  data2: null,
  tipoRelatorio: 'processos'
}

export default {
  name: 'RelatorioProcessoClientesWindow',
  mixins: [mixinPrint, processoMixins],
  data() {
    return {
      formato: 'html',
      filters: JSON.parse(JSON.stringify(filters)),
      cache: {
        tiposParte: {
          list: [],
          loading: false,
          selected: null
        }
      },
      fakeCliente: null
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    searchPersonTags(terms, done) {
      searchTags(terms)
          .then(response => {
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let data1, data2
      let filters = JSON.parse(JSON.stringify(this.filters))
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }
      if (filters.participants && Array.isArray(filters.participants) && filters.participants.length) {
        filters.participants = filters.participants.map(p => p.id)
      }
      if (filters.participants && filters.participants.id) {
        filters.participants = [filters.participants.id]
      }

      this.loading = true
      relatorio('processos', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Clientes', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    ClassificacaoProcessoSelect,
    DateInput,
    TipoProcessoSelect,
    VaraSelect,
    ComarcaSelect,
    TribunalSelect,
    StatusProcessoSelect,
    // AutocompleteMultiple,
    ClienteProcessoInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    // URadio,
    ErpSelect
  }
}
</script>
