<script>
export default {
  methods: {
    loadCache (fieldName, fn, fnParameters = [], callbackParseData = c => ({label: c.nome, value: c.id})) {
      this.cache[fieldName].loading = true
      fn(...fnParameters)
          .then(response => {
            console.log(fieldName, response.data)
            this.cache[fieldName].list = (response.data.result || response.data || []).map(callbackParseData)
          })
          .catch((error) => {
            this.alertApiError(error)
            console.log(error)
          })
          .finally(() => {
            this.cache[fieldName].loading = false
          })
    }
  }
}
</script>
