<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchCliente"
        readonly
    >
    </erp-input>
    <a @click="searchCliente"><i class="erp-icon search min"></i> </a>
<!--    <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="abrirCadastro" />-->
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import searchCliente from '../window/search/SearchClienteProcesso'

export default {
  name: 'ClienteProcessoInput',
  mixins: [InputMixin],
  data () {
    return {
      findedData: null,
      description: null
    }
  },
  components: {HelperInputBtn},
  methods: {
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.descricao)
      this.$emit('selecionaCliente', this.findedData)
    },
    searchCliente
  }
}
</script>
