import http from '../../../utils/services/http'

export const getStatus = () => {
  let url = `/api/processos/all-status`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getClassificacao = (status = null) => {
  let url = `/api/processos/classificacao-all`
  if (status) {
    url = url + '?status=' + status
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
